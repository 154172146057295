export const enableIdleVue = false;

export const pricing = {
    baseUrl: "https://pricing.reducer.co.uk/"
}

export const docusign = {
    baseUrl: "https://app.docusign.com/"
};

export const userleap = {
    id: "kQQ6lLpPyS"
};

export const mouseflow = {
    id: "7f3f6fb7-ce4a-4127-a891-84a528d78a57"
};

export const cognito = {
    userPoolId: "us-east-1_g8vZwPYd1",
    clientId: "2nkvvjtls3uvtgs8p8kc3j18a9",
    callBackUrl: "https://reducer.co.uk/app/CognitoLanding",
    signoutUrl: "https://reducer.co.uk/app/sign-in",
    endPoint: "https://auth.reducer.co.uk"
};

export const api = {
    dev: false,
    apiEndPoint: "/api/"
};

export const app = {
    type: "reducer",
    name: "Reducer",
    currencySymbol: "£"
};

export const sso = {
    quickbooks: true,
    xero: true,
    google: true
};

export const url = {
    homepage: "https://reducer.co.uk",
    pagenotfound: "https://reducer.co.uk/static-public/error/404.html"
};